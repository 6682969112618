.product-description.desktop {
  header {
    border-bottom: 1px solid white;
  }
}

.product-description.mobile {
  header {
    border-bottom: 1px solid white;
  }
  
  section {
    padding: 10px;
  }
}

.description-markup{
  padding: 10px 0px;
  font-size: 1.1em;
  letter-spacing: 0.2px;
  line-height: 30px;

  p {
    margin-top: 20px;
  }
}