.product-pictures.desktop {
  height: 274px;
  width: 354px;
  display: grid;
  grid-gap: 18px;
  grid-template-areas: "s1 m m m" "s2 m m m" "s3 m m m";
  
  .small-picture {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #404040;
  }

  .small-picture:first-of-type {
    grid-area: s1;
  }
  
  .small-picture:nth-of-type(2) {
    grid-area: s2;
  }
  

  .last-small-area {
    grid-area: s3;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #303030;
    position: relative;
  }

  .medium-picture {
    grid-area: m;
    width: 278px;
    height: 278px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #303030;
  }

  
  .small-picture img {
    max-width: 80px;
    max-height: 80px;
    background: #252525;
  }
  
  .medium-picture img {
    max-width: 278px;
    max-height: 278px;
    background-color: #252525;
  }
  
  .last-small-area img {
    max-width: 80px;
    max-height: 80px;
    background: #202020;
  }

  .youtube-button-holder {
    position: relative;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    
    .inner {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 25px;
      top: 10px;
    }
  
  }
  
  button {
    padding: 0;
    cursor: pointer;
  }
  
  .modal {
    max-height: 90%;
  }
  
  .modal-content {
    padding: 10px 61px 41px;
    max-height: 85%;
    img {
      max-height: 500px;
    }
  }
}
