.know-the-difference {
    > div {
        display:flex;
        flex-direction: row;

        
        div {

            height: 100%;
            display: block;

            h2 {
                padding-bottom: 20px;
            }
        }


        .horizontal-buttons {
            display:flex;
            flex-direction: row;
        }

        padding: 30px 0px;
        clear:both;

        button {
            display: flex;
            height: 200px;
            width: 200px;
            padding-right: 20px;
            background: none;
    
            img {
                max-width: 100%;
                max-height: 100%;
                float:left;
            }
        }
    ::after {
        clear:both;
    }
    }
    
    p {
        line-height: 26px;
        display:block;
    }


    h1 {
        margin-top:31px;
        margin-bottom: 20px;
    }
}

.big-modal-image {
    max-width: 600px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    

    p {
        border-top: 3px solid #666666;
        margin-top: 20px;
        padding-top: 10px;
        line-height: 26px;
    }
}
