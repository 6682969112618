.any-questions.mobile {
  text-align: center;
  color: #DFDFDF; 
  margin-top: 15px;
  
  .any-question--call-me{
    display: flex;
    flex-direction: column;
    align-items: center;
    .call-me-form {
      h2 {
        display: none;
      }
    }
    margin-bottom: 15px;
  }
  
  .any-question--info{
  }
}