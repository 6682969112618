a.email {
  color: white;
  font-weight: bold;
  
  &:focus, &:hover {
    border-bottom: 1px solid #30A2de;
  }
  
  &:active {
    color: #30A2de;
  }
}