.price-tag--background {
  width: 100%;
  margin-right:20px;
  background: #343434;
  border-radius: 4px;
  border-top: 7px solid #343434;
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 15px;
    background-image: radial-gradient(circle, #202020 0%, #202020 43%, #343434 43%);
    background-size: 31px 31px;
    background-repeat: repeat-x;
  } 
}

.price-tag.mobile {
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  
  .dropdown {
    display: block;
    width: 80%;
    margin: 10px auto;
  }
  
  .price-and-in-stock {
    width: 80%;
    margin: 15px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  
    span.price {
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      color: #30A2DE;
    }
    
    .in-stock {
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      
      &.is-in-stock::before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 10px;
        border-bottom: 2px solid #7ED321;
        border-right: 2px solid #7ED321;
        transform: rotate(45deg);
        margin-right: 7px;
      }
    }
  }
  
  &>button {
    margin: 20px auto 0;
    display: block;
  }
  
  // CSSTransition
  
  .to-bottom-slider {
    &-enter {
      height: 0;
      transform-origin: top;
      transform: scale(1, 0);
      opacity: 0;
    }
    
    &-enter-active { 
      transform: scale(1, 1);
      height: auto;
      opacity: 1;
      transition: transform 400ms, height 400ms, opacity 400ms ease-in;
    }
    
    &-exit {
      transform-origin: top;
      transform: scale(1, 1);
      height: auto;
      opacity: 1;
    }
    
    &-exit-active {
      transform: scale(1, 0);
      opacity: 0;
      height: 0;
      transition: transform 400ms, height 400ms;
    }
  }
}