.price-tag.desktop {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  
  width: 231px;
  height: 194px;
  padding-bottom: 23px;
  background: #343434;
  border-radius: 4px;
  border-top: 7px solid #4E4E4E;
  background-image: radial-gradient(circle, #202020 0%, #202020 43%, #343434 43%);
  background-size: 31px 31px;
  background-repeat: repeat-x;
  background-position: 6px 174px;
  
  span.price {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #30A2DE;
  }
  
  .in-stock {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    
    &.is-in-stock::before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 10px;
      border-bottom: 2px solid #7ED321;
      border-right: 2px solid #7ED321;
      transform: rotate(45deg);
      margin-right: 7px;
    }
  }
}