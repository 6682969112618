.loading-splashcreen {
    width: 100%;
    height: 100%;
    min-height: 400px;
    text-align: center;
    font-size: 24px;
    color: #DFDFDF;

    span {
        display: inline-block;
        margin-top: 100px;
    }
}