.showcase-item {
  ul.advantages {
    li::before {
      content: "";
    }
    
    li:first-of-type::before {
      background-image: url("../../../../images/icons/crusher_reliable.png");
      background-size: cover;
    }
    
    li:nth-of-type(2)::before {
      background-image: url("../../../../images/icons/crusher_easytouse.png");
      background-size: cover;      
    }
    
    li:last-of-type::before {
      background-image: url("../../../../images/icons/crusher_mobility.png");
      background-size: cover;
    }

    &.boiler {
      li:first-of-type::before {
        background-image: url("../../../../images/icons/boiler_productive.png");
        background-size: cover;
      }
      
      li:nth-of-type(2)::before {
        background-image: url("../../../../images/icons/boiler_autonomy.png");
        background-size: cover;      
      }
      
      li:last-of-type::before {
        background-image: url("../../../../images/icons/boiler_easytorepair.png");
        background-size: cover;
      }
    }
  }
}

.showcase-item.desktop {
  ul.advantages {
    box-sizing: border-box;
    list-style: none;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 235px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin: 29px 93px 35px 0;

    li {
      display: inline-block;
      width: 360px;
    }
    
    li::before {
      height: 51px;
      width: 51px;
      margin-right: 15px;
      float: left;
    }
  }
}