.nav-footer.mobile {
  background-color: #151515;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #DFDFDF;
  padding: 15px 0;

  .footer-menu p {
    padding: 10px;
  }
  
  .contact-block {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    
    .working-hours {
      padding-top: 20px;
    }
    
    .phone-number {
      margin: 10px 10px 0;
      font-size: 24px;
      line-height: auto;
      color: #30a2de;
      
      &:focus, &:active {
        color: #ffffff;
        border-bottom: 2px solid #30a2de;
      }
    }

    > button {
      margin: 10px 10px 0;
    }
  }
  
  .call-me-form--background {
    margin: 10px 0 0 15px;
    padding-top: 10px;
    width: 90%;
    min-width: 260px;
    max-width: 400px;
    background: #343434;
    border-radius: 4px;
    border-top: 7px solid #4E4E4E;
    
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 15px;
      background-image: radial-gradient(circle, #151515 0%, #151515 43%, #343434 43%);
      background-size: 31px 31px;
      background-repeat: repeat-x;
    } 
    
    .call-me-form {
      margin: 0 auto;
      
      div {
        margin: 0 auto;
      }
    }
  }
  
  // CSSTransition
  
  .to-bottom-slider {
    &-enter {
      height: 0;
      transform-origin: top;
      transform: scale(1, 0);
      opacity: 0;
    }
    
    &-enter-active { 
      transform: scale(1, 1);
      height: auto;
      opacity: 1;
      transition: transform 400ms, height 400ms, opacity 400ms ease-in;
    }
    
    &-exit {
      transform-origin: top;
      transform: scale(1, 1);
      opacity: 1;
    }
    
    &-exit-active {
      transform: scale(1, 0);
      opacity: 0;
      transition: transform 200ms, opacity 200ms ease-in;
    }
  }
}