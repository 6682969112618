.contact-info.mobile {
  width: 100%;
  max-width: 430px;
  padding: 5px 10px 45px 31px;
  box-sizing: border-box;
  background: #202020;
  
  h1 {
    margin: 20px 0;
 }
  
  dl { 
    font-size: 16px;
    line-height: auto;
    margin-bottom: 10px;
    
    dt {
      font-weight: 500;
      display: inline-block;
      vertical-align: top;
    }
    
    dd {
      font-weight: normal;
      display: inline-block;
      white-space: normal;
    }
    
    a.phone-number {
    margin-left: 10px; 
    }
    
    dd.email {
    margin-left: 10px; 
    }
    
    dd.address {
    margin-left: 10px;
    }

    dd.working-hours {
      margin-left: 80px;
    }
  }
  
  .call-me-form {
    h2 {
      text-align: left;
    }
  }
}
