.default-button {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 8px 30px 9px;
  
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #FFFFFF;
  }
  
  &:hover {
    background: #30A2DE;
  }
  
  &:active {
    background: #202020;
  }
}

.blue-button {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  background-color: #30A2DE;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 8px 30px 9px;
  border: none;
  
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #FFFFFF;    
  }
  
  &:hover {
    box-shadow: inset 0 0 0 2px #FFFFFF;        
  }
  
  &:active {
    background: #18516F;
  }
}

.blue-square-button {
  border: none;
  border-top: 1px solid #30A2DE;
  padding: 5px 36px 7px;
  background: linear-gradient(180deg, #30A2DE 0%, #1472A3 97.54%);
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  
  &:focus {
    box-shadow: inset 0 0 0 1px #ffffff;
    outline: none;
  }
  
  &:hover {
    background: linear-gradient(180deg, #4AAEE2 0%, #3184AF 97.54%);
  }
  
  &:active {
    background: linear-gradient(180deg, #247AA6 0%, #0F567A 97.54%);
  }
}

.long-button {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  background-color: #30A2DE;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 8px 30px 9px;
  width: 260px;
  border: none; 
  
  &:focus {
    outline: none; 
    box-shadow: inset 0 0 0 2px #FFFFFF;    
  }
  
  &:hover {
    box-shadow: inset 0 0 0 2px #FFFFFF;        
  }
  
  &:active {
    background: #18516F;
  }
}
  
.showcase-link {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  background: transparent;
  box-sizing: border-box;
  padding: 0;  
  border: none;
  transition: text-shadow 400ms;
  
  &::after {
  display: block;
  box-sizing: border-box;
  position: relative;
  content: "";
  height: 9px;
  width: 9px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: #202020;
  }
  
  &:focus {
    outline: none;
    text-shadow: #aaaaaa 0 -1px 1px, #202020 0 0 12px;
        
    &::after {
      display: block;
      box-sizing: border-box;
      position: relative;
      content: "";
      height: 10px;
      width: 10px;
      border: 1px solid #30A2DE;
      border-radius: 50%;
      background-color: #18516F;
      }
  }
  
  &:hover {
    color: #3f3f3f;
  }
  
  &:active {
    color: #30A2DE;
  }
  
  &.active::after {
  background: #30A2DE;
  border: 1px solid #30A2DE;
  }
}

.tab-switch-button {
  background: transparent;
  box-sizing: border-box;  
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #DFDFDF;
  border: none;
  padding: 8px 15px 10px;
  
  &:focus {
    outline: none;
    color: #30A2DE;
  }
  
  &:hover {
    color: #30A2DE;
  }
  
  &:active {
    color: #18516F;
  }
  
  &.active {
    border-bottom: 5px solid #30A2DE;
    margin-bottom: -1px;
  }
}

.transparent-button {
  background: transparent;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  padding: 13px 17px 13px 18px;
  
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #FFFFFF;
  }
  
  &:hover {
    background: #30A2DE;
  }
  
  &:active {
    background: #202020;
  }
}

.show-details-button {
  display: block;
  padding: 0;
  line-height: 24px;
  color: #30A2DE;
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed #30A2DE;
  
  &:focus {
    outline: none;
    border-color: #FFFFFF;
    // font-weight: 600;
  }
  
  &:hover {
    border-color: #FFFFFF;
    color: #FFFFFF;
    // font-weight: 600;
  }
  
  &:active {
    border-color: #30A2DE;
  }
}