.choose-model.desktop {
  padding-top: 50px;
  
  display: grid;
  grid-template-columns: 375px 320px 235px;
  grid-template-rows: 20px 240px;
  grid-gap: 25px;
  
  .product-pictures {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
  
  h2.product-title {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    margin-top:10px;
  }
  
}

.choose-model.mobile {
  display: grid;
  grid-template-columns: 100%; 
  grid-gap: 20px;
  padding: 10px;

  h2.product-title { 
    text-align: center;
  }
  
  .feature-table {
    height: auto;
    width: 100%;
    margin-right:20px;
  }
} 