.about.desktop {
  .any-questions {
    margin-bottom: 30px;
  }
}

.about.mobile {
  padding: 30px 3px 30px 5px; 
  .any-questions {
    margin-bottom: 15px;
  }
}