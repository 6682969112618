.products-nav.mobile {
  background-color: #484848;
  
  a {
    color: #ffffff;
    padding: 10px;
    
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
    
  ul.product-category {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
    border-bottom: 2px solid #202020;
    
    li {
      flex-grow: 1;
      display: inline-block;
      text-align: center;
      
      a {
        display: inline-block;
        width: 100%;
      }
    }
  }
    
  ul.product-lines {
    list-style: none;
    background-color: #343434;

    li {
      display: inline-block;
      margin-right: 15px;
      padding: 10px;
    }
  }
    
  ul.product-category {    
    a.active-category-link {
      background-color: #343434;
    }
  }
  
  ul.product-lines {
    a.active-prodline-link {
      background-color: #343434;
      border-bottom: 5px solid #30A2DE;
    }
  }
}