.landing-info {
  h2 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    a {      
      font-weight: normal;
      line-height: 32px;
      color: #ffffff;
    }
  }
  
  h2::after {
    content: "";
    display: block;
    width: 101px;
    border: 1px solid #30A2DE;
    background: #30A2DE;
    position: relative;
    top: 5px;
  }
  
  .why-us {
    min-height: 450px;
    display: block;
    padding-bottom: 30px;
    
    ul {
      padding-left: 38px;
      border-left: 1px solid
       #FFFFFF;
      height: 355px;
      li {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        margin: 27px 0;
      }
    }
    
    .align-center {
      text-align: center;
      a {
        border-bottom: 1px solid #FFFFFF;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        position: baseline;
      }
    }
    
  }
}

.landing-info.desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0px 100px;
  
  .why-us {
    ul {
      margin-top: 44px;
    }
  }
}

.landing-info.mobile {
  padding: 40px 20px;
  
  .why-us {
    
    ul {
      height: 400px;
      margin-top: 34px;
      display: table;
    }

    
    .align-center {
      text-align: center;
      margin-top:10px;
      a {
        border-bottom: 1px solid #FFFFFF;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        position: baseline;
      }
    }
  }
}