.showcase-item.mobile {
  .big-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 10px 0;
  }

  .showcase-item-description {
    width: 100%;
    height: 300px;
    vertical-align: middle;

    &.boiler {
      height: 260px;

      .image-container {
        margin-top: 30px;
      }

      .children-container {
        margin-top: 0px;
      }
    }

    .image-container {
      width: 50%;
      float: left;
      margin-top:50px;
      max-height: 100%;
      vertical-align: middle;
      img {
        max-width: 99%;
        float:right;
      }
    }

    .children-container {
      width: 49%;
      float: right;
      margin-right: 1%;
      margin-top: 27px;
      > * {
        float:left;
        max-width:90%;
        margin-left:10%;
      }
    }
  }

  .clear-fix {
    clear: both;
    width: 100%;
  }

  .to-catalog {
    margin-bottom: 20px;

    a.to-catalog-button {
      display: block;
      box-sizing: border-box;
      width: 50%;
      max-width: 250px;
      color: #ffffff;
      padding: 13px 0;
      margin: 10px auto;
      text-align: center;
      background: transparent;
      border: 1px solid #ffffff;
      border-radius: 5px;
      text-transform: uppercase;

      &:focus,
      &:active {
        color: #3f3f3f;
        border-color: #3f3f3f;
        box-shadow: 0 0 0 2px #3f3f3f;
      }
    }
  }
}
