.default-button-mobile {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 8px 30px 9px;
  
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #FFFFFF;
  }
  
  &:active {
    background: #202020;
  }
}

.blue-square-button--mobile {
  border: none;
  border-top: 1px solid #30A2DE;
  padding: 5px 15px 7px;
  background: linear-gradient(180deg, #30A2DE 0%, #1472A3 97.54%);
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  
  &:focus {
    box-shadow: inset 0 0 0 1px #ffffff;
    outline: none;
  }
  
  &:active {
    background: linear-gradient(180deg, #247AA6 0%, #0F567A 97.54%);
  }
}

.tab-switch-button--mobile {
  display: block;
  background: transparent;
  box-sizing: border-box;  
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
  padding: 15px 0 2px 15px;
  
  &:focus {
    outline: none;
    color: #30A2DE;
  }
  
  &:hover {
    color: #30A2DE;
  }
  
  &.active {
    border-bottom: 2px solid #30A2DE;    
    margin-bottom: -1px;
  }
  
  &:last-of-type:not(.active) {
    border: none;
  }
}

.show-details-button--mobile {
  line-height: 22px;
  color: #30A2DE;
  background-color: transparent;
  border: 1px dashed #30A2DE;
  font-weight: 600;
  
  &:focus {
    outline: none;
    border-color: #FFFFFF;
  }
  
  &:active {
    color: #dedede;
  }
}