.product-pictures.mobile {
  max-width: 100%;

  img {
    width: 100%;
  }
  
  iframe {
    width: 100%;
    height: 400px;
  }
  
  .control-row {

    button {
      height: 50px;
      background: #333333;
      width:50%;
      color: white;
    }
  }
}