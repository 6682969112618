.showcase-item.mobile {
  ul.advantages {
    box-sizing: border-box;
    list-style: none;
    font-style: normal;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    li {
      display: inline-flex;
      margin: 10px 0;
      width: 100%;
      max-width: 300px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    
    li::before {
      flex-shrink: 0;
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }
  }
}