.footer-disclaimer {
  background-color: #000000;
  mix-blend-mode: normal;
  opacity: 0.58;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  
  &.desktop {
    padding: 15px;
  }
  
  &.mobile {
    padding: 15px 10px;
  }
}