.showcase-item.desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  .showcase-item-description { 
    font-family: "Roboto";
    color: #fefefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 40px; 
    
    h1.big-title {
      width: 465px;
      font-size: 36px;
      line-height: 42px;
      text-transform: uppercase;
      text-align: left;
      color: #fefefe;
      min-height: 80px;
    }
    
    .to-catalog {
      width: 470px;
      padding-left: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      
      ul li {
        list-style: none;
        
        a {
          border-bottom: 1px dashed #FFFFFF;
          font-weight: normal;
          font-size: 18px;
          line-height: 32px;
          color: #ffffff;
          
          &:focus, &:hover {
            // font-weight: 500;
            color: #3F3F3F;
            border-color: #3F3F3F;
          }
          
          &:active {
            color: #ffffff;
            border-color: #ffffff;
          }
        }
      }
      
      a.to-catalog-button {
        background: transparent;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 20px;
        color: #ffffff;
        padding: 13px 17px 13px 18px;
        
        &:focus, &:hover {
          color: #3F3F3F;
          border-color: #3F3F3F;
          box-shadow: 0 0 0 2px #3F3F3F;
        }
        
        &:active {
          color: #ffffff;
          border-color: #ffffff;
          box-shadow: 0 0 0 2px #ffffff;
        }
      }
    }
  }
}
