.header-mobile {
  background-color: #151515;
  padding-bottom: 5px;
  
  .menu-button {
    align-self: flex-end;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    margin: 15px 0 10px 15px;
    
    &:focus, &:hover {
      outline: none;
    }
  }
  
  .logo {
    margin-top: 15px;
    vertical-align: baseline;
  }

  button.blue-square-button--mobile { 
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 5px;
    width:100%;
  }
  
  .button-and-phone {
    float: right;
    text-align: right;
    margin-bottom: 5px;
    padding-right: 0px;
    
    button {
      display: block;
      margin-bottom: 10px;
    }
    
    .phone-number {
      padding-right: 5px;
      font-size: 18px;
    }
  }
  
  .clearfix {
    display: table;
    clear: both;
  }
  
  &.light-theme {
    background-color: #353535;
  }
  
  .call-me-form {
    margin: 0 auto;
    
    h2 {
      margin-bottom: none;
    }
    
    span {
      display: block;
      text-align: center;
    }
    
    div {
      margin: 0 auto;
    }
  }
  
  // CSS Transition and container for menu and CallMeForm
  
  .container-menu-mobile {
    &.enter {
      height: 0;
      transform-origin: top;
      transform: scale(1, 0);
      opacity: 0;
    }
    
    &.enter-active {
      transform: scale(1, 1);
      height: auto;
      opacity: 1;
      transition: transform 400ms, height 400ms, opacity 400ms ease-in;
      padding: 0 10px 10px;
      border-bottom: 2px solid #30a2de;
    }
    
    &.enter-done {
      padding: 0 10px 10px;
      border-bottom: 2px solid #30a2de;
    }
    
    &.exit {
      height: auto;
      transform-origin: top;
      transform: scale(1, 1);
      opacity: 1;
    }
    
    &.exit-active {
      height: 0;
      transform: scale(1, 0);
      opacity: 0;
      transition: transform 400ms, opacity 400ms ease-in, height 400ms;
    }
  }
}