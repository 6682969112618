$rain: #2C3845;

.feature-const {
  width: 580px;
  display: inline-block;
}

.feature-table-const{
  width: 100%;
  height:600px;
  overflow-x: scroll;

  tr:nth-child(2n+1) td {
    background-color: #242e39;
  }

  tr {
    display: table-row;
  }

  td, th {
    padding: 10px;
  }

  td {
    border: 1px solid #28333f; 
  }
}

.feature-title{
  width:380px;
}

.feature-current-item{
  text-align: center;
  min-width: 110px;
}

.feature-dynamic-item {
  min-width: 110px;
}

.feature-table-dynamic{
  width: 100%;
  height:600px;
  
  tr:nth-child(2n+1) td {
    background-color: #242e39;
  }

  tr {
    display: table-row;
  }

  td, th {
    padding: 10px;
    text-align: center;
  }

  td {
    border: 1px solid #28333f;
  }
}

.feature-dynamic {
  width: 400px;
  display: inline-block;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  height: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(255,255,255,0.5);
}

.plug {
  width: 100%;
  height: 20px;
  box-shadow: inset 0 0 6px rgba(255,255,255,0.4);
}