.phone-header.desktop {
  background: #000000;
  text-align: right;
  font-size: 14px;
  line-height: 23px;

  .phone-number {
    font-weight: bold;
    display: inline-block;
    padding: 2px 25px 5px 20px;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    vertical-align: middle;
  }
}

//при включении светлой темы цвет текста (кроме текста кнопки) становится прозрачнее
.phone-header.desktop {
  &.light-theme {
    span, .phone-number { 
    mix-blend-mode: normal;
    color: rgba(255, 255, 255, 0.79);
    }
  }
}
