.feature-table {
  height: 240px;
  width: 350px;
  display: block;
  overflow: hidden;
  
  ul {
    display: table-row;
    font-size: 16px;
    color: #DFDFDF;
    
    &:nth-last-of-type(2n) {
      background-color: #343434;
    }
    
    li {
      display: table-cell;
      vertical-align: middle;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 15px;
      word-break:normal;
      text-align: left;

      &:first-of-type{    
        padding-left: 15px;
        width:140px
      }

      &:nth-of-type(2) {
        padding-left: 5px;
        padding-right: 5px;
        font-weight: bold;
      }

      &:last-of-type {
        padding-left: 10px;
        width:70px;
      }
    }
  }
  
  div {
    display: table-footer-group;
    
    a {
      font-size: 16px;
      line-height: 28px;
      color: #30A2DE;
      border-bottom: 1px dashed #30A2DE;
    }
  }
}

.mobile {
  .feature-table {
    width: 100%;
    ul {
      width: 100%;
      li {
        &:first-of-type {
          width: 47%;
        }

        &:nth-of-type(2) {
          width: 20%;
          padding-left: 3%;
        }

        &:last-of-type {
          width: 30%;
        }
      }
    }
  }
}
