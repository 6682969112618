.delivery-page.desktop {
  section.pay-and-delivery {
    margin-top: 50px;
  }
  
  section.questions {
    margin: 50px 0 100px;
  }
}

.delivery-page.mobile {
  padding: 15px;
  
  section.pay-and-delivery {
    margin-top: 30px;
  }
  
  section.questions {
    margin: 30px 0 50px;
  }
}