.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.9);
  border: 2px solid #3F3D3D;
  border-radius: 2px;
  
  .modal-header {
    height: 20px;
    padding: 5px;
    
    .close {
    float: right;
    background-color: transparent;
    font-size: 30px;
    color: #eeeeee;
    border: none;
    
      &:focus {
        outline: none;
        color: #30A2DE;
      }
      
      &:hover {
        color: #30A2DE;
      }
      
      &:active {
        color: #18516F;
      }
    }
  }
  
  .modal-content {
    margin: auto;
    padding: 0 31px 31px;
  }
}