.order-form.mobile {
  box-sizing: border-box;
  
  
  &:focus {
    outline: none;
  }
  
  form {
    width: 100%;
    padding: 15px 15px 0;
    color: #DFDFDF;
  }
  
  p.model {
    margin-bottom: 18px;
    width: 90%;
    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      padding-right: 0.5em;
    }
  }
  
  p.comment {
    line-height: 20px;
    white-space: normal;
  }
  
  .input-and-button {
    margin: 18px 0;
    max-width: 260px;
    
    label {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
    }
    
    input {
      display: block;
      width: 100%;
      height: 34px;
      padding: 8px 12px;
      margin-top: 14px;
      background: #333333;
      border: 1px solid #555555;
      box-sizing: border-box;
      border-radius: 17px; 
      color: #dddddd;
      font-size: 14px;
      line-height: 16px;
      
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px #ffffff;
      }
    }
    
    .long-button {
      display: block;
      margin-top: 16px;
    }
    
    p.error {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: #EA3C21;
      margin: 2px 0 -19px 5px;
    }
  }
}
