section.pay-and-delivery.mobile {
  color: #DFDFDF;
  
  h1 {
    margin-bottom: 10px; 
  }
  
  h2 {
    margin-bottom: 5px;
    margin-top: 20px;
  }

  p {
    padding: 5px 3px;
  }
  
  ul.blue-bullets-list {
    padding: 5px 10px;
    line-height: 24px;
    
    li {
      margin-bottom: 10px;
    }
    
    li:before {
      display: inline-block;
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #30A2DE;
      vertical-align: middle; 
      margin-right: 10px;
    }
  }
}

