.products-nav.desktop {
  background-color: #484848;
  
  .container-big {
    // height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 65px 0;
    
    .product-category {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 28px;
      padding: 15px 0 15px;
      margin-right: 43px;
    }
    
    ul.product-lines {
      list-style: none;

      li {
        display: inline-block;
        margin-right: 23px;
        padding: 0 15px;
        
        a {
          display: inline-block;
          color: #ffffff;
          padding: 17px 10px 12px;
          
          span {
            padding-bottom: 1px;
          }
        
          &:hover, &:focus {
            span {
              border-bottom: 1px solid #ffffff;
              padding-bottom: 0px;
            }
          }
          
          &:active {
            color: #30a2de;
          }
        }
      }
    }
    
    .product-link-active {
      background-color: #343434;
      border-bottom: 5px solid #30A2DE;
    }
  }
}