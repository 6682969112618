﻿.overlay-picture {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-picture {
  background-color: rgba(0, 0, 0, 0.7);
  border: 2px solid #3F3D3D;
  border-radius: 2px;
  max-width:96%;
  max-height: 90%;
}

.modal-picture-header {
  min-height: 40px;
  border-bottom: 2px solid #3F3D3D;
  display: block;
  
  div {
    display: flex;
    flex-direction: row;
    width: 100%;

    .title {
      font-size: 35px;
      line-height: 40px;
      color: #eeeeee;
      border: none;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      width: 96%;
      text-align: center;
      flex-direction: column;
    }

    .crosshair-area {
      display: flex;
      background-color: transparent;
      font-size: 40px;
      color: #eeeeee;
      text-align: center;

      span {
        height: 100%;
      }
      &:focus {
        outline: none;
        color: #30A2DE;
      }

      &:hover {
        color: #30A2DE;
      }

      &:active {
        color: #18516F;
      }
    }

  }
}

  .modal-picture-content {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top:20px;
    flex-direction: column;
    max-height: 700px;
    
    img {
      height:100%;
      max-height: 700px;
    }
    
    iframe {
      min-height: 650px;
      max-height: 88%;

    }
  }
  
  .modal-picture-footer {
    min-height: 40px;
    display: flex;
    border-top: 2px solid #3F3D3D;
    margin-top: 15px;
    clear: both;
  }