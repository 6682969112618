.any-questions.desktop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: #DFDFDF; 
  
  > div.any-question--info {
    margin-right: 45px;
    
    h2 {
      margin-bottom: 30px;
    }
    
    p {
      margin-bottom: 10px;
      
      span {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  
  .any-question--call-me{
    padding-bottom: 10px;
    
    .call-me-form {
      h2 {
        display: none;
      }
    }
  }
}