.showcase {
  /*CSSTransition*/
  .carousel {
    &-enter{
      opacity: 0;
    }
    
    &-enter-active { 
      opacity: 1;
      transition: opacity 800ms ease-in;
    }
    
    &-exit {
      opacity: 1;
    }
    
    &-exit-active {
      opacity: 0;
      transition: opacity 800ms ease-in;
    }
  }
}