.showcase {
  background-image: linear-gradient(to bottom, #949597 0%, #A1A2A4 40%, #828284 70%, #828284 80%, #949597 100%);
  
  .showcase-carousel {
    box-sizing: border-box;
    height: 625px;
    width: 100%;
  }
  
  .showcase-menu {
    background-color: #a0a1a2;
    border-bottom: 1px dashed #FFFFFF;

    > .container-big {  
      height: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      
      .showcase-link {
        margin: 5px 150px 0;
      } 
      
      .showcase-link::after {
        top: 7px;
        left: 45%;
      }
      /*The rest styles for this showcase-link button are in shared/button/button.scss*/
    }
  }
}